<template>
  <v-data-table
    id="content"
    :headers="headers"
    :items="productLines"
    :search="search"
    sort-by="productFamily"
    :loading="loading"
    :loading-text="$t('admin.productLines.loadingWait')"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title
          ><h2>{{ $t("admin.productLines.title") }}</h2></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('admin.productLines.search')"
          single-line
          hide-details
          style="max-width: 300px;"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          text
          icon
          color="primary"
          :disabled="loading"
          @click="showSettings()"
        >
          <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
        <v-btn text icon color="primary" :disabled="loading" @click="addItem()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <DataTableSettings
          :items="dataTableItems"
          @updateSettings="updateSettings($event)"
          :key="dataTableSettingsKey"
        />

        <v-dialog
          v-model="dialog"
          persistent
          :fullscreen="$vuetify.breakpoint.smAndDown"
          max-width="520px"
        >
          <ProductLineDialog
            :item="editedItem"
            @close="close"
            @productLinesUpdated="productLinesUpdated"
            :key="productLineDialogKey"
          />
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.bwl1="{ item }">
      <v-simple-checkbox v-model="item.bwl1" disabled></v-simple-checkbox>
    </template>
    <template v-slot:item.bwl2="{ item }">
      <v-simple-checkbox v-model="item.bwl2" disabled></v-simple-checkbox>
    </template>
    <template v-slot:item.bwl3="{ item }">
      <v-simple-checkbox v-model="item.bwl3" disabled></v-simple-checkbox>
    </template>
    <template v-slot:item.bwl4="{ item }">
      <v-simple-checkbox v-model="item.bwl4" disabled></v-simple-checkbox>
    </template>
    <template v-slot:item.bwl5="{ item }">
      <v-simple-checkbox v-model="item.bwl5" disabled></v-simple-checkbox>
    </template>
    <template v-slot:item.bwl6="{ item }">
      <v-simple-checkbox v-model="item.bwl6" disabled></v-simple-checkbox>
    </template>
    <template v-slot:item.bwl7="{ item }">
      <v-simple-checkbox v-model="item.bwl7" disabled></v-simple-checkbox>
    </template>
    <template v-slot:item.bwl8="{ item }">
      <v-simple-checkbox v-model="item.bwl8" disabled></v-simple-checkbox>
    </template>
    <template v-slot:item.active="{ item }">
      <v-simple-checkbox v-model="item.active" disabled></v-simple-checkbox>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon class="mr-2" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <span>{{ $t("admin.productLines.noProductLinesFound") }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

import DataTableSettings from "@/components/common/DataTableSettings";
import ProductLineDialog from "@/components/admin/ProductLine/ProductLineDialog";

import { api } from "@/helpers/ApiCalls";

export default {
  name: "ProductLinesAdmin",

  components: {
    DataTableSettings,
    ProductLineDialog,
  },

  data() {
    return {
      headersList: [
        {
          text: this.$t("admin.productLines.productFamilyLabel"),
          value: "productFamily",
        },
        {
          text: this.$t("admin.productLines.productTypeLabel"),
          value: "productType",
        },
        {
          text: this.$t("admin.productLines.pitchLevelLabel"),
          value: "pitchLevel",
        },
        {
          text: this.$t("admin.productLines.tpiLabel"),
          value: "tpi",
        },
        {
          text: this.$t("admin.productLines.averagePitchLabel"),
          value: "averagePitch",
        },
        {
          text: this.$t("admin.productLines.averageGulletAreaLabel"),
          value: "averageGulletArea",
        },
        {
          text: this.$t("admin.productLines.gulletFillLabel"),
          value: "gulletFill",
        },
        {
          text: this.$t("admin.productLines.bwl1Label"),
          value: "bwl1",
        },
        {
          text: this.$t("admin.productLines.bwl2Label"),
          value: "bwl2",
        },
        {
          text: this.$t("admin.productLines.bwl3Label"),
          value: "bwl3",
        },
        {
          text: this.$t("admin.productLines.bwl4Label"),
          value: "bwl4",
        },
        {
          text: this.$t("admin.productLines.bwl5Label"),
          value: "bwl5",
        },
        {
          text: this.$t("admin.productLines.bwl6Label"),
          value: "bwl6",
        },
        {
          text: this.$t("admin.productLines.bwl7Label"),
          value: "bwl7",
        },
        {
          text: this.$t("admin.productLines.bwl8Label"),
          value: "bwl8",
        },
        {
          text: this.$t("admin.productLines.activeLabel"),
          value: "active",
        },
        {
          text: this.$t("admin.productLines.edit"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      headers: [],
      search: "",
      dialog: false,
      editedIndex: -1,
      editedItem: {
        productLineID: 0,
        productFamily: "",
        productTypeID: "",
      },
      defaultItem: {
        productLineID: 0,
        productFamily: "",
        productTypeID: "",
      },
      loading: false,
      dataTableItems: [],
    };
  },

  computed: {
    ...mapGetters([
      "productLines",
      "productLinesSettings",
      "dataTableSettingsKey",
      "productLineDialogKey",
    ]),
  },

  methods: {
    getProductLines() {
      this.loading = true;

      this.$store.dispatch("getProductLines").then(
        (response) => {
          this.loading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);

          this.updateHeaders();
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);

          if (api.authenticationError(error.code)) {
            setTimeout(function () {
              router.push({ name: "Home" }).catch(() => {});
            }, api.authErrorRedirectTimeout);
          }
        }
      );
    },
    showSettings() {
      this.updateDataTableItems();
      this.$store.dispatch("showDataTableSettingsDialog", true);
    },
    updateSettings(params) {
      this.$store.dispatch("saveProductLinesSettings", params);

      this.updateHeadersFromSettings(params.headers);
    },
    updateHeaders() {
      if (this.settings()) {
        this.updateHeadersFromSettings(this.productLinesSettings.headers);
      } else {
        this.headers = [...this.headersList];
      }
    },
    updateHeadersFromSettings(headers) {
      var values = headers
        .filter((item) => item.selected)
        .map((item) => {
          return item.value;
        });

      this.headers = this.headersList.filter((item) =>
        values.includes(item.value)
      );
    },
    updateDataTableItems() {
      if (this.settings()) {
        this.dataTableItems = this.productLinesSettings.headers;
      } else {
        let items = this.headersList.map((o) => ({
          text: o.text + " (" + o.value + ")",
          value: o.value,
          selected: true,
        }));

        items.sort(function (a, b) {
          if (!a.text || !b.text) {
            return 0;
          }

          var x = a.text.toLowerCase();
          var y = b.text.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        this.dataTableItems = items;
      }

      this.$store.dispatch("reloadProductLinesSettingsComponent");
    },
    settings() {
      return (
        this.productLinesSettings && this.productLinesSettings.headers != null
      );
    },
    addItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = true;
    },
    editItem(item) {
      this.editedIndex = this.productLines.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.editedItem.bwl1 = this.editedItem.bwl1 ? 1 : 0;
      this.editedItem.bwl2 = this.editedItem.bwl2 ? 1 : 0;
      this.editedItem.bwl3 = this.editedItem.bwl3 ? 1 : 0;
      this.editedItem.bwl4 = this.editedItem.bwl4 ? 1 : 0;
      this.editedItem.bwl5 = this.editedItem.bwl5 ? 1 : 0;
      this.editedItem.bwl6 = this.editedItem.bwl6 ? 1 : 0;
      this.editedItem.bwl7 = this.editedItem.bwl7 ? 1 : 0;
      this.editedItem.bwl8 = this.editedItem.bwl8 ? 1 : 0;
      this.editedItem.active = this.editedItem.active ? 1 : 0;

      this.dialog = true;
    },
    deleteItem(item) {
      const action = this.$t("admin.productLines.deleteRecord");
      if (!confirm(action)) {
        return;
      }

      this.itemOverlay = true;

      this.$store.dispatch("deleteProductLine", { productLine: item }).then(
        (response) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
          this.productLinesUpdated();
        },
        (error) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    productLinesUpdated() {
      this.dialog = false;
      this.getProductLines();
      this.$emit("productLinesUpdated", false);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  mounted() {
    this.getProductLines();
  },
};
</script>
