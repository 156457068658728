<template>
  <div>
    <v-card outlined>
      <v-form ref="itemForm">
        <v-card-text class="pa-0 px-3">
          <v-container fluid class="pa-0 px-3">
            <v-row>
              <v-col cols="12" sm="3">
                <v-text-field
                  autofocus
                  :label="$t('admin.productTypeDialog.productTypeNameLabel')"
                  v-model="item.productTypeName"
                  :rules="itemInputRules"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="
                    $t('admin.productTypeDialog.productTypeDescriptionLabel')
                  "
                  v-model="item.productTypeDescription"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  :label="$t('admin.productTypeDialog.orderInListLabel')"
                  v-model="item.orderInList"
                  type="number"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
                <v-btn
                  depressed
                  tile
                  v-html="$t('admin.productTypeDialog.cancelButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  @click="cancel()"
                ></v-btn>
              </v-col>
              <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
                <v-btn
                  depressed
                  tile
                  v-html="$t('admin.productTypeDialog.saveButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="saveDisabled"
                  @click="save(item)"
                ></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ProductTypeDialog",

  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      itemInputRules: [
        (v) =>
          (!!v && v.length > 0) ||
          this.$t("admin.productTypeDialog.inputRules"),
      ],
    };
  },

  computed: {
    itemValid() {
      if (!this.item) {
        return false;
      }

      let valid =
        this.item != null &&
        this.item.productTypeName != undefined &&
        this.item.productTypeName != "";

      return valid;
    },
    saveDisabled() {
      if (!this.itemValid) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    save() {
      this.$emit("save", this.item);
    },
    cancel() {
      this.$emit("cancel", false);
    },
  },
};
</script>
