<template>
  <div>
    <v-card outlined>
      <v-form ref="itemForm">
        <v-card-text class="pa-0 px-3">
          <v-container fluid class="pa-0 px-3">
            <v-row>
              <v-overlay :absolute="true" :value="itemOverlay">
                <v-progress-circular
                  indeterminate
                  size="32"
                ></v-progress-circular>
              </v-overlay>
              <v-col cols="12" sm="6" class="pl-2 pr-7 pb-2">
                <v-text-field
                  autofocus
                  :label="$t('admin.productLines.productFamilyLabel')"
                  :disabled="itemOverlay"
                  v-model="item.productFamily"
                  :rules="itemInputRules"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pl-2 pr-7 pb-2">
                <v-text-field
                  :label="$t('admin.productLines.productTypeLabel')"
                  :disabled="itemOverlay"
                  v-model="item.productType"
                  :rules="itemInputRules"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="px-2 pt-0">
                <v-text-field
                  :label="$t('admin.productLines.pitchLevelLabel')"
                  :disabled="itemOverlay"
                  v-model="item.pitchLevel"
                  :rules="itemInputRules"
                  type="number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="px-2 pt-0">
                <v-text-field
                  :label="$t('admin.productLines.tpiLabel')"
                  :disabled="itemOverlay"
                  v-model="item.tpi"
                  :rules="itemInputRules"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" class="px-2 pt-0">
                <v-text-field
                  :label="$t('admin.productLines.averagePitchLabel')"
                  :disabled="itemOverlay"
                  v-model="item.averagePitch"
                  :rules="itemInputRules"
                  type="number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="px-2 pt-0">
                <v-text-field
                  :label="$t('admin.productLines.averageGulletAreaLabel')"
                  :disabled="itemOverlay"
                  v-model="item.averageGulletArea"
                  :rules="itemInputRules"
                  type="number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="px-2 pt-0">
                <v-text-field
                  :label="$t('admin.productLines.gulletFillLabel')"
                  :disabled="itemOverlay"
                  v-model="item.gulletFill"
                  :rules="itemInputRules"
                  type="number"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="2" class="px-2 pt-0">
                <span class="card-text-caption">
                  {{ $t("admin.productLines.bwl1Label") }}
                </span>
              </v-col>
              <v-col cols="12" sm="4" class="px-2 pt-0">
                <v-btn-toggle dark v-model="item.bwl1" mandatory dense>
                  <v-btn color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("admin.productLines.noLabel") }}
                    </span>
                  </v-btn>
                  <v-btn depressed tile color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("admin.productLines.yesLabel") }}
                    </span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="12" sm="2" class="px-2 pt-0">
                <span class="card-text-caption">
                  {{ $t("admin.productLines.bwl2Label") }}
                </span>
              </v-col>
              <v-col cols="12" sm="4" class="px-2 pt-0">
                <v-btn-toggle dark v-model="item.bwl2" mandatory dense>
                  <v-btn color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("admin.productLines.noLabel") }}
                    </span>
                  </v-btn>
                  <v-btn depressed tile color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("admin.productLines.yesLabel") }}
                    </span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="2" class="px-2 pt-0">
                <span class="card-text-caption">
                  {{ $t("admin.productLines.bwl3Label") }}
                </span>
              </v-col>
              <v-col cols="12" sm="4" class="px-2 pt-0">
                <v-btn-toggle
                  depressed
                  tile
                  dark
                  v-model="item.bwl3"
                  mandatory
                  dense
                >
                  <v-btn color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("admin.productLines.noLabel") }}
                    </span>
                  </v-btn>
                  <v-btn color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("admin.productLines.yesLabel") }}
                    </span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="12" sm="2" class="px-2 pt-0">
                <span class="card-text-caption">
                  {{ $t("admin.productLines.bwl4Label") }}
                </span>
              </v-col>
              <v-col cols="12" sm="4" class="px-2 pt-0">
                <v-btn-toggle
                  depressed
                  tile
                  dark
                  v-model="item.bwl4"
                  mandatory
                  dense
                >
                  <v-btn color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("admin.productLines.noLabel") }}
                    </span>
                  </v-btn>
                  <v-btn color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("admin.productLines.yesLabel") }}
                    </span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="2" class="px-2 pt-0">
                <span class="card-text-caption">
                  {{ $t("admin.productLines.bwl5Label") }}
                </span>
              </v-col>
              <v-col cols="12" sm="4" class="px-2 pt-0">
                <v-btn-toggle dark v-model="item.bwl5" mandatory dense>
                  <v-btn color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("admin.productLines.noLabel") }}
                    </span>
                  </v-btn>
                  <v-btn depressed tile color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("admin.productLines.yesLabel") }}
                    </span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="12" sm="2" class="px-2 pt-0">
                <span class="card-text-caption">
                  {{ $t("admin.productLines.bwl6Label") }}
                </span>
              </v-col>
              <v-col cols="12" sm="4" class="px-2 pt-0">
                <v-btn-toggle
                  depressed
                  tile
                  dark
                  v-model="item.bwl6"
                  mandatory
                  dense
                >
                  <v-btn color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("admin.productLines.noLabel") }}
                    </span>
                  </v-btn>
                  <v-btn color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("admin.productLines.yesLabel") }}
                    </span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="2" class="px-2 pt-0">
                <span class="card-text-caption">
                  {{ $t("admin.productLines.bwl7Label") }}
                </span>
              </v-col>
              <v-col cols="12" sm="4" class="px-2 pt-0">
                <v-btn-toggle
                  depressed
                  tile
                  dark
                  v-model="item.bwl7"
                  mandatory
                  dense
                >
                  <v-btn color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("admin.productLines.noLabel") }}
                    </span>
                  </v-btn>
                  <v-btn color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("admin.productLines.yesLabel") }}
                    </span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="12" sm="2" class="px-2 pt-0">
                <span class="card-text-caption">
                  {{ $t("admin.productLines.bwl8Label") }}
                </span>
              </v-col>
              <v-col cols="12" sm="4" class="px-2 pt-0">
                <v-btn-toggle
                  depressed
                  tile
                  dark
                  v-model="item.bwl8"
                  mandatory
                  dense
                >
                  <v-btn color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("admin.productLines.noLabel") }}
                    </span>
                  </v-btn>
                  <v-btn color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("admin.productLines.yesLabel") }}
                    </span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="2" class="px-2 pt-0">
                <span class="card-text-caption">
                  {{ $t("admin.productLines.activeLabel") }}
                </span>
              </v-col>
              <v-col cols="12" sm="10" class="px-2 pt-0">
                <v-btn-toggle
                  depressed
                  tile
                  dark
                  v-model="item.active"
                  mandatory
                  dense
                >
                  <v-btn color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("admin.productLines.noLabel") }}
                    </span>
                  </v-btn>
                  <v-btn color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("admin.productLines.yesLabel") }}
                    </span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
                <v-btn
                  depressed
                  tile
                  v-html="$t('admin.machines.cancelButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="itemOverlay"
                  @click="close()"
                ></v-btn>
              </v-col>
              <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
                <v-btn
                  depressed
                  tile
                  v-html="$t('admin.machines.saveButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="saveDisabled"
                  @click="saveProductLine(item)"
                ></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ProductLineDialog",

  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      itemOverlay: false,
      itemInputRules: [
        (v) =>
          (!!v && v.length > 0) || this.$t("admin.productLines.inputRules"),
      ],
    };
  },

  computed: {
    itemValid() {
      if (!this.item) {
        return false;
      }

      let valid =
        this.item != null &&
        this.item.productFamily != undefined &&
        this.item.productFamily != "" &&
        this.item.productType != undefined &&
        this.item.productType != "" &&
        this.item.pitchLevel != null &&
        this.item.pitchLevel >= 1 &&
        this.item.pitchLevel <= 12 &&
        this.item.tpi != undefined &&
        this.item.tpi != "" &&
        this.item.averagePitch != null &&
        this.item.averagePitch >= 0 &&
        this.item.averageGulletArea != null &&
        this.item.averageGulletArea >= 0 &&
        this.item.gulletFill != null &&
        this.item.gulletFill >= 0;

      return valid;
    },
    scissorPivotEnabled() {
      return this.item.machineOrientation == 0;
    },
    saveDisabled() {
      if (!this.itemValid || this.itemOverlay) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    saveProductLine() {
      this.itemOverlay = true;

      let functionName = "";

      if (!this.item.productLineID) {
        functionName = "createProductLine";
      } else {
        functionName = "updateProductLine";
      }

      this.$store
        .dispatch(functionName, {
          productLine: this.item,
        })
        .then(
          (response) => {
            this.itemOverlay = false;

            const notification = {
              show: true,
              result: true,
              message: response.message,
            };
            this.$store.dispatch("showSnackbar", notification);

            this.$refs.itemForm.reset();
            this.$emit("productLinesUpdated", false);
          },
          (error) => {
            this.itemOverlay = false;

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }
            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };
            this.$store.dispatch("showSnackbar", notification);
          }
        );
    },
    close() {
      this.$emit("close", false);
    },
  },
};
</script>
