<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col class="py-0">
        <ProductLines
          @productLinesUpdated="reloadProductTypes" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <ProductTypeDataTable
          :title="$t('admin.productTypeDataTable.productTypeNameLabel')"
          :items="productTypes"
          :itemsPerPage="5"
          :dataLoading="productTypesLoading"
          @save="saveProductType"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

import ProductLines from "@/components/admin/ProductLine/ProductLines";
import ProductTypeDataTable from "@/components/admin/ProductLine/ProductTypeDataTable";

import { api } from "@/helpers/ApiCalls";

export default {
  name: "ProductLinesAdmin",

  components: {
    ProductLines,
    ProductTypeDataTable,
  },

  data() {
    return {
      productTypesLoading: false,
    };
  },

  computed: {
    ...mapGetters(["productTypes", "productLineDialogKey"]),
  },

  methods: {
    getProductTypes() {
      this.productTypesLoading = true;

      this.$store.dispatch("getProductTypes").then(
        (response) => {
          this.productTypesLoading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.productTypesLoading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);

          if (api.authenticationError(error.code)) {
            setTimeout(function () {
              router.push({ name: "Home" }).catch(() => {});
            }, api.authErrorRedirectTimeout);
          }
        }
      );
    },
    saveProductType(item) {
      if (!item) {
        return;
      }

      let functionName = "";

      if (item.productTypeID == undefined || item.productTypeID == 0) {
        functionName = "createProductType";
      } else {
        functionName = "updateProductType";
      }

      this.productTypesLoading = true;

      this.$store.dispatch(functionName, item).then(
        (response) => {
          this.productTypesLoading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);

          this.getProductTypes();
        },
        (error) => {
          this.productTypesLoading = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    reloadProductTypes() {
      this.getProductTypes();
    },
  },

  mounted() {
    this.getProductTypes();
  },
};
</script>
